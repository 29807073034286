import { track } from "@vercel/analytics";
import Banner from "../assets/new-arrivals.png";
import { BASE_URL } from "../utils";
export default function HolidayBanner() {
  return (
    <div
      className="relative w-full overflow-hidden rounded-lg cursor-pointer"
      onClick={() => {
        track("new_arrivals");
        window
          .open(
            `${BASE_URL}collections/all-pets?sort_by=created-descending?utm_source=Insta_website&utm_medium=instagram%2Cwebsite%2CFidoFashionStatement%2CShihTzu&utm_campaign=Insta_FidoFashionStatement&utm_co&utm_term=instawebFidoFashionStatement`,
            "_blank"
          )
          .focus();
      }}
    >
      <div className="aspect-[16/9] w-full">
        <img
          src={Banner}
          alt="Holiday banner background"
          layout="fill"
          objectFit="cover"
          className="rounded-lg"
        />
      </div>
    </div>
  );
}
